import classNames from 'classnames';
import IconHome from 'components/Icons/IconHome';
import IconPlus from 'components/Icons/IconPlus';
import IconZap from 'components/Icons/IconZap';
import useSetNavItems from 'components/QuickMenu/hooks/useSetNavItems';
import useTrappedMode, {
  TrappedMode,
} from 'components/QuickMenu/hooks/useTrappedMode';
import { NavItemType } from 'components/QuickMenu/types';
import useElectronIpc from 'hooks/useElectronIpc';
import useHotkey from 'hooks/useHotkey';
import { useUpdateModal } from 'hooks/useModal';
import { useRouter } from 'next/router';
import React, { useCallback } from 'react';
import { ModalType } from 'types/modal';
import { isHomePage, isRootPage } from 'utils/router';
import ContactList from './ContactList';
import { useAvatarsProximityTracking } from './EagerEventsLoader';
import NavigationItem from './NavigationItem';
import NavigationList from './NavigationList';
import { CONTACTPANEL_WIDTH } from './utils';
import UserItem from './UserItem';
import useFeatureFlag from 'hooks/useFeatureFlag';
import IconInbox from 'components/Icons/IconInbox';
import CalendarButton from './CalendarButton';
import IconInboxActive from 'components/Icons/IconInboxActive';

interface Props {
  isSidepanelExpanded: boolean;
  toggleSidepanel: (value?: boolean) => void;
}

export default function LeftPanel({
  toggleSidepanel,
  isSidepanelExpanded,
}: Props): JSX.Element {
  const router = useRouter();
  const features = useFeatureFlag();
  const { isElectron } = useElectronIpc();
  const { openModal } = useUpdateModal();
  const { setTrappedMode } = useTrappedMode();
  const { addNavItem } = useSetNavItems();
  const goToHome = useCallback(() => router.push('/home'), [router]);
  const goToCalendar = useCallback(() => router.push('/'), [router]);

  const openCalendarSelect = useCallback(
    (event) => {
      event.preventDefault();
      setTrappedMode(TrappedMode.AddCalendar);
      openModal(ModalType.QuickMenu);
    },
    [setTrappedMode, openModal]
  );

  const openTimezoneSelect = useCallback(
    (event) => {
      event.preventDefault();
      addNavItem({ type: NavItemType.timezones });
      openModal(ModalType.QuickMenu);
    },
    [addNavItem, openModal]
  );
  const openSettings = useCallback(
    (event) => {
      event.preventDefault();
      openModal(ModalType.Settings);
    },
    [openModal]
  );

  useHotkey('a', 'global', openCalendarSelect);
  useHotkey('h', 'global', goToHome);
  useHotkey('c', 'global', goToCalendar);
  useHotkey('z', 'global', openTimezoneSelect);
  useHotkey('command+,', 'global', openSettings);

  const { startTracking, stopTracking } = useAvatarsProximityTracking();

  return (
    <div
      style={{ minWidth: CONTACTPANEL_WIDTH }}
      className={classNames(
        'hidden-scrollbar border-separator bg-background flex max-h-full flex-col items-center overflow-y-scroll border-r transition-borderWidth duration-100',
        {
          'pt-10': isElectron,
          'pt-3': !isElectron,
          'border-r': isSidepanelExpanded,
          'border-0': !isSidepanelExpanded,
        }
      )}
      onMouseEnter={startTracking}
      onMouseLeave={stopTracking}
    >
      <NavigationList>
        <NavigationItem
          tooltipContent="Calendar"
          transparent
          shortcut="C"
          onClick={goToCalendar}
          active={isRootPage(router)}
        >
          <CalendarButton active={isRootPage(router)} />
        </NavigationItem>

        <NavigationItem
          tooltipContent="Todos"
          shortcut="L"
          onClick={() => toggleSidepanel(!isSidepanelExpanded)}
          active={isSidepanelExpanded}
        >
          {isSidepanelExpanded ? <IconInboxActive /> : <IconInbox />}
        </NavigationItem>

        {features.feed && (
          <NavigationItem
            tooltipContent="Feed"
            shortcut="F"
            onClick={goToHome}
            active={isHomePage(router)}
          >
            <IconHome className="h-5 w-5" />
          </NavigationItem>
        )}
      </NavigationList>

      <div className="mx-auto my-3 w-6">
        <hr className="border-separator" />
      </div>

      <ContactList />

      <NavigationList>
        <NavigationItem
          shortcut="A"
          tooltipContent="Add calendar"
          onClick={openCalendarSelect}
        >
          <IconPlus className="h-3.5 w-3.5" />
        </NavigationItem>
      </NavigationList>

      <div className="mt-auto flex pt-3">
        <NavigationList>
          <NavigationItem
            shortcut="⌘J"
            tooltipContent="Quick menu"
            onClick={() => openModal(ModalType.QuickMenu)}
          >
            <IconZap />
          </NavigationItem>

          <UserItem />
        </NavigationList>
      </div>

      {/* Spacer */}
      <div className="flex h-3 w-full flex-shrink-0" />
    </div>
  );
}
